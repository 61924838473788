import { CSSProperties } from 'react'

export type Icons =
  | 'hide'
  | 'cross'
  | 'augmented-reality-cube'
  | 'checkmark'
  | 'chevron-left'
  | 'chevron-right'
  | 'single-star'
  | 'single-star-big'
  | 'single-star-big-partially-filled'
  | 'arrow-right'
  | 'arrow-left'
  | 'instagram'
  | 'youtube'
  | 'pinterest'
  | 'facebook'
  | 'cart'
  | 'account'
  | 'delivery-car'
  | 'return-circle'
  | 'mail'
  | 'heart'
  | 'plus'
  | 'cart-2'
  | 'sample-2'
  | 'error'
  | 'show-password'
  | 'hide-password'
  | 'sample-box-large'
  | 'sample-box-small'
  | 'filter-new'

export type Props = {
  name: Icons
  className?: string
  viewBox?: string
  setWidth?: number
  setHeight?: number
  style?: CSSProperties
  percent?: number
}

export const Icon = ({
  name,
  className = '',
  viewBox,
  setWidth,
  setHeight = setWidth,
  style,
  percent,
}: Props) => {
  const selectIcon = () => {
    switch (name) {
      case 'hide':
        return {
          width: 24,
          height: 24,
          icon: (
            <>
              <path
                d="M13.542 13.59c-.2.221-.44.398-.708.521a2.126 2.126 0 0 1-2.431-.464 2.256 2.256 0 0 1-.48-.748 2.313 2.313 0 0 1 .03-1.759 2.25 2.25 0 0 1 .505-.73m5.862 6.045A7.183 7.183 0 0 1 12 18c-5.09 0-8-6-8-6a13.734 13.734 0 0 1 3.68-4.455l8.64 8.91ZM10.473 6.18c.5-.12 1.013-.181 1.527-.18 5.09 0 8 6 8 6a13.91 13.91 0 0 1-1.57 2.393L10.472 6.18ZM4 4l16 16"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </>
          ),
        }
      case 'arrow-right':
        return {
          width: 24,
          height: 24,
          icon: (
            <>
              <title>Arrow Right</title>
              <desc>An arrow pointing right</desc>
              <path
                d="M3 12h18M15 18l6-6-6-6"
                stroke="currentColor"
                strokeWidth={1.3}
                strokeLinecap="round"
              />
            </>
          ),
        }
      case 'arrow-left':
        return {
          width: 24,
          height: 24,
          icon: (
            <>
              <title>Arrow Reft</title>
              <desc>An arrow pointing left</desc>
              <path
                d="M21 12H3M9 6l-6 6 6 6"
                stroke="currentColor"
                strokeWidth={1.3}
                strokeLinecap="round"
              />
            </>
          ),
        }
      case 'cross':
        return {
          width: 24,
          height: 24,
          icon: (
            <path
              d="M17.657 17.657 6.343 6.343M17.657 6.343 6.343 17.657"
              stroke="currentColor"
              strokeWidth={1.3}
              strokeLinecap="round"
            />
          ),
        }
      case 'augmented-reality-cube':
        return {
          width: 24,
          height: 24,
          icon: (
            <>
              <path
                d="M19.72 6.05a.497.497 0 0 1 .227.226l.008.015a.478.478 0 0 1 .045.22V10a.5.5 0 1 1-1 0V7.308l-2.276 1.138a.5.5 0 0 1-.448-.895L18.382 6.5l-2.106-1.053a.5.5 0 1 1 .448-.895l2.995 1.499ZM20 16.49a.506.506 0 0 1-.145.362.497.497 0 0 1-.136.097l-2.995 1.498a.5.5 0 0 1-.448-.894l2.106-1.053-2.106-1.053a.5.5 0 0 1 .448-.894l2.277 1.138V13A.5.5 0 1 1 20 13v3.489Zm-15.382.01 2.106 1.053a.5.5 0 0 1-.447.894L3.28 16.95a.497.497 0 0 1-.228-.225l-.007-.015-.014-.033A.52.52 0 0 1 3 16.49V13a.5.5 0 1 1 1 0v2.69l2.277-1.137a.5.5 0 1 1 .447.894L4.618 16.5ZM4 7.309V10a.5.5 0 0 1-1 0V6.51a.494.494 0 0 1 .281-.46l2.996-1.498a.5.5 0 0 1 .447.895L4.618 6.5l2.106 1.052a.5.5 0 0 1-.447.895L4 7.309Zm7.268-5.252a.496.496 0 0 1 .465 0l2.99 1.496a.5.5 0 1 1-.447.894L12 3.31V6a.5.5 0 1 1-1 0V3.31L8.724 4.447a.5.5 0 0 1-.448-.894l2.992-1.496Zm.465 18.886a.499.499 0 0 1-.465 0l-2.992-1.496a.5.5 0 0 1 .447-.894L11 19.69v-2.692a.5.5 0 0 1 1 0v2.692l2.276-1.138a.5.5 0 1 1 .447.894l-2.99 1.495ZM12 10.808v2.69a.5.5 0 1 1-1 0v-2.69L8.276 9.447a.5.5 0 0 1 .447-.895L11.5 9.941l2.776-1.389a.5.5 0 1 1 .447.895L12 10.809Z"
                fill="currentColor"
              />
            </>
          ),
        }
      case 'single-star':
        return {
          width: 16,
          height: 16,
          icon: (
            <>
              <path
                d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z"
                fill="currentColor"
              />
            </>
          ),
        }
      case 'single-star-big-partially-filled':
        return {
          width: 24,
          height: 22,
          icon: (
            <>
              <defs>
                <linearGradient id="percent" x1="0" y1="0" x2="1" y2="0">
                  <stop id="stop1" offset={`${percent || 0}%`} stopColor="black" />
                  <stop id="stop2" offset={`${percent || 0}%`} stopColor="lightgrey" />
                </linearGradient>
              </defs>
              <path
                d="M12 0L14.6942 8.2918H23.4127L16.3593 13.4164L19.0534 21.7082L12 16.5836L4.94658 21.7082L7.64074 13.4164L0.587322 8.2918H9.30583L12 0Z"
                fill="url(#percent)"
              />
            </>
          ),
        }
      case 'single-star-big':
        return {
          width: 24,
          height: 22,
          icon: (
            <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.4062 6.00815e-05H0.5V15.3165H15.4062V6.00815e-05Z" fill="currentColor" />
              <path
                d="M7.953 10.3227L10.22 9.7323L11.1672 12.7318L7.953 10.3227ZM13.1702 6.44568H9.1797L7.953 2.58466L6.7264 6.44568H2.7358L5.9655 8.8389L4.7389 12.6999L7.9685 10.3067L9.956 8.8389L13.1702 6.44568Z"
                fill="white"
              />
            </svg>
          ),
        }
      case 'instagram':
        return {
          width: 24,
          height: 24,
          icon: (
            <>
              <path
                d="M12 2.16c3.206 0 3.586.015 4.847.071 1.172.052 1.805.249 2.226.413a3.71 3.71 0 0 1 1.379.895c.421.422.68.82.895 1.378.164.422.36 1.06.412 2.227.057 1.265.07 1.645.07 4.847 0 3.206-.013 3.586-.07 4.846-.051 1.172-.248 1.805-.412 2.227a3.71 3.71 0 0 1-.895 1.378 3.69 3.69 0 0 1-1.379.895c-.421.165-1.059.361-2.226.413-1.266.056-1.645.07-4.847.07-3.206 0-3.586-.014-4.847-.07-1.172-.052-1.805-.248-2.226-.413a3.71 3.71 0 0 1-1.379-.895 3.691 3.691 0 0 1-.895-1.378c-.164-.422-.36-1.06-.412-2.227-.057-1.265-.07-1.645-.07-4.846 0-3.207.013-3.586.07-4.847.051-1.172.248-1.805.412-2.227.216-.558.478-.96.895-1.378.422-.422.82-.68 1.379-.895.421-.164 1.059-.361 2.226-.413 1.261-.056 1.64-.07 4.847-.07ZM12 0C8.742 0 8.334.014 7.055.07 5.78.127 4.903.333 4.144.628a5.857 5.857 0 0 0-2.128 1.388A5.88 5.88 0 0 0 .628 4.139C.333 4.903.127 5.775.07 7.05.014 8.334 0 8.742 0 12s.014 3.666.07 4.945c.057 1.275.263 2.152.558 2.911.31.792.717 1.463 1.388 2.128a5.866 5.866 0 0 0 2.123 1.383c.764.296 1.636.502 2.911.558 1.28.056 1.688.07 4.945.07 3.258 0 3.666-.014 4.946-.07 1.275-.056 2.151-.262 2.91-.558a5.866 5.866 0 0 0 2.124-1.383 5.866 5.866 0 0 0 1.383-2.123c.295-.764.501-1.636.558-2.911.056-1.28.07-1.688.07-4.945 0-3.258-.014-3.666-.07-4.946-.057-1.275-.263-2.151-.558-2.91a5.62 5.62 0 0 0-1.374-2.133A5.866 5.866 0 0 0 19.861.633C19.097.338 18.225.13 16.95.075 15.666.015 15.258 0 12 0Z"
                fill="currentColor"
              />
              <path
                d="M12 5.836A6.166 6.166 0 0 0 5.836 12 6.166 6.166 0 0 0 12 18.164 6.166 6.166 0 0 0 18.164 12 6.166 6.166 0 0 0 12 5.836Zm0 10.162A3.999 3.999 0 1 1 12.001 8 3.999 3.999 0 0 1 12 15.998ZM19.847 5.592a1.44 1.44 0 1 1-2.879 0 1.44 1.44 0 0 1 2.879 0Z"
                fill="currentColor"
              />
            </>
          ),
        }
      case 'checkmark':
        return {
          width: 24,
          height: 24,
          icon: (
            <path
              d="m4 13 5 5L20 7"
              stroke="currentColor"
              strokeWidth={1.3}
              strokeLinecap="round"
            />
          ),
        }
      case 'chevron-left':
        return {
          width: 8,
          height: 14,
          icon: (
            <>
              <title>Chevron Left</title>
              <desc>A chevron pointing left</desc>
              <path
                d="M7 1L1 7L7 13"
                stroke="currentColor"
                strokeWidth={1.3}
                strokeLinecap="round"
              />
            </>
          ),
        }
      case 'chevron-right':
        return {
          width: 8,
          height: 14,
          icon: (
            <>
              <title>Chevron Right</title>
              <desc>A chevron pointing right</desc>
              <path
                d="M1 13L7 7L1 1"
                stroke="currentColor"
                strokeWidth={1.3}
                strokeLinecap="round"
              />
            </>
          ),
        }
      case 'plus':
        return {
          width: 24,
          height: 24,
          icon: (
            <>
              <title>Plus</title>
              <desc>A plus</desc>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.65 4a.65.65 0 1 0-1.3 0v7.35H4a.65.65 0 1 0 0 1.3h7.35V20a.65.65 0 1 0 1.3 0v-7.35H20a.65.65 0 1 0 0-1.3h-7.35V4Z"
                fill="currentColor"
              />
            </>
          ),
        }
      case 'youtube':
        return {
          width: 24,
          height: 24,
          icon: (
            <>
              <path
                d="M23.76 7.2s-.233-1.655-.955-2.381c-.914-.956-1.936-.961-2.405-1.017-3.356-.244-8.395-.244-8.395-.244h-.01s-5.039 0-8.395.244c-.469.056-1.49.06-2.405 1.017C.473 5.545.244 7.2.244 7.2S0 9.145 0 11.086v1.819c0 1.94.24 3.886.24 3.886s.233 1.654.95 2.38c.915.957 2.115.924 2.65 1.027 1.92.183 8.16.24 8.16.24s5.044-.01 8.4-.249c.469-.056 1.49-.06 2.405-1.017.722-.727.956-2.381.956-2.381S24 14.85 24 12.905v-1.819c0-1.94-.24-3.886-.24-3.886ZM9.52 15.113V8.367l6.483 3.385-6.483 3.36Z"
                fill="currentColor"
              />
            </>
          ),
        }
      case 'pinterest':
        return {
          width: 24,
          height: 24,
          icon: (
            <>
              <path
                d="M12 0C5.372 0 0 5.372 0 12c0 5.086 3.164 9.427 7.627 11.175-.104-.952-.202-2.405.042-3.44.22-.938 1.406-5.963 1.406-5.963s-.36-.717-.36-1.781c0-1.67.965-2.916 2.17-2.916 1.021 0 1.518.769 1.518 1.692 0 1.031-.656 2.569-.994 3.994-.28 1.195.6 2.17 1.777 2.17 2.133 0 3.773-2.25 3.773-5.494 0-2.873-2.062-4.884-5.01-4.884-3.413 0-5.42 2.56-5.42 5.208 0 1.031.4 2.137.896 2.737a.359.359 0 0 1 .084.343c-.089.38-.295 1.195-.332 1.359-.052.22-.174.267-.404.16-1.5-.7-2.437-2.888-2.437-4.65 0-3.788 2.752-7.262 7.926-7.262 4.163 0 7.397 2.968 7.397 6.933 0 4.135-2.606 7.463-6.225 7.463-1.214 0-2.357-.633-2.751-1.378 0 0-.6 2.292-.745 2.854-.272 1.04-1.004 2.349-1.491 3.146C9.572 23.812 10.763 24 12 24c6.628 0 12-5.372 12-12S18.628 0 12 0Z"
                fill="currentColor"
              />
            </>
          ),
        }
      case 'facebook':
        return {
          width: 24,
          height: 24,
          icon: (
            <>
              <path
                d="M24 12c0-6.627-5.373-12-12-12S0 5.373 0 12c0 5.99 4.388 10.954 10.125 11.854V15.47H7.078V12h3.047V9.356c0-3.007 1.792-4.668 4.533-4.668 1.312 0 2.686.234 2.686.234v2.953H15.83c-1.491 0-1.956.925-1.956 1.875V12h3.328l-.532 3.469h-2.796v8.385C19.612 22.954 24 17.99 24 12Z"
                fill="currentColor"
              />
            </>
          ),
        }
      case 'cart':
        return {
          width: 30,
          height: 30,
          icon: (
            <>
              <path
                d="M5.37641 3.26825L5.37649 3.26823L5.37556 3.26544C5.34921 3.18639 5.29727 3.10887 5.23267 3.05073C5.16844 2.99292 5.08672 2.95 5 2.95H2.375C2.109 2.95 1.95 3.16407 1.95 3.375C1.95 3.50221 1.98836 3.60985 2.06425 3.68575C2.14015 3.76164 2.24779 3.8 2.375 3.8H4.6333L9.07649 20.7309C9.07651 20.731 9.07654 20.7311 9.07656 20.7311C9.12882 20.9398 9.28863 21.05 9.45312 21.05H25.25C25.4609 21.05 25.675 20.891 25.675 20.625C25.675 20.515 25.6205 20.4092 25.5432 20.3318C25.4658 20.2545 25.36 20.2 25.25 20.2H9.81982L8.86169 16.55H24.8281C25.553 16.55 26.1355 16.1138 26.3293 15.4356L26.3294 15.4354L28.8604 6.43624C28.8604 6.43612 28.8605 6.436 28.8605 6.43587C29.1514 5.46545 28.3761 4.45 27.3594 4.45H5.69457L5.37641 3.26825ZM8.64791 15.7L5.90863 5.3H27.3594C27.8419 5.3 28.1924 5.78375 28.0615 6.22001L28.0612 6.22084L25.53 15.2206C25.4439 15.5221 25.1399 15.7 24.8281 15.7H8.64791ZM10.25 22.45C8.95608 22.45 7.95 23.5043 7.95 24.75C7.95 26.0432 8.95676 27.05 10.25 27.05C11.4957 27.05 12.55 26.0439 12.55 24.75C12.55 23.5036 11.4964 22.45 10.25 22.45ZM11.7 24.75C11.7 25.5651 11.0203 26.2 10.25 26.2C9.43386 26.2 8.8 25.5661 8.8 24.75C8.8 23.9797 9.43487 23.3 10.25 23.3C11.0193 23.3 11.7 23.9807 11.7 24.75ZM23.75 27.05C24.9957 27.05 26.05 26.0439 26.05 24.75C26.05 23.5036 24.9964 22.45 23.75 22.45C22.4561 22.45 21.45 23.5043 21.45 24.75C21.45 26.0432 22.4568 27.05 23.75 27.05ZM22.3 24.75C22.3 23.9797 22.9349 23.3 23.75 23.3C24.5193 23.3 25.2 23.9807 25.2 24.75C25.2 25.5651 24.5203 26.2 23.75 26.2C22.9339 26.2 22.3 25.5661 22.3 24.75Z"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="0.1"
              />
            </>
          ),
        }
      case 'cart-2':
        return {
          width: 20,
          height: 20,
          icon: (
            <>
              <path
                d="M4.50687 15.137L2.93611 10.6682C2.47888 9.36735 3.44412 8.00494 4.82294 8.00494H15.2924C16.6379 8.00494 17.5996 9.30683 17.2041 10.5929L15.8296 15.0617C15.5715 15.901 14.7961 16.4737 13.918 16.4737H6.3937C5.5448 16.4737 4.78837 15.9378 4.50687 15.137Z"
                stroke="currentColor"
                strokeWidth="1.5"
              />
              <path
                d="M10.5642 2.99992C10.5642 2.58571 10.2284 2.24992 9.81421 2.24992C9.4 2.24992 9.06421 2.58571 9.06421 2.99992L10.5642 2.99992ZM10.5642 7.61926L10.5642 2.99992L9.06421 2.99992L9.06421 7.61926L10.5642 7.61926Z"
                fill="currentColor"
              />
            </>
          ),
        }
      case 'sample-2':
        return {
          width: 20,
          height: 20,
          icon: (
            <>
              <path
                d="M10.2718 11.1366H4.9823V9.84195H10.2718V4.48926H11.6928V9.84195H16.9823V11.1366H11.6928V16.4893H10.2718V11.1366Z"
                fill="currentColor"
              />
            </>
          ),
        }
      case 'error':
        return {
          width: 18,
          height: 18,
          icon: (
            <>
              <path
                d="M9 7.3125V10.125"
                stroke="#CF3030"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.02638 2.81143L1.84055 13.4984C1.74157 13.6694 1.68937 13.8634 1.68921 14.061C1.68905 14.2586 1.74092 14.4528 1.83962 14.6239C1.93832 14.7951 2.08036 14.9372 2.25144 15.0361C2.42253 15.1349 2.61663 15.187 2.81421 15.187H15.1859C15.3835 15.187 15.5776 15.1349 15.7486 15.0361C15.9197 14.9372 16.0618 14.7951 16.1605 14.6239C16.2592 14.4528 16.311 14.2586 16.3109 14.061C16.3107 13.8634 16.2585 13.6694 16.1595 13.4984L9.9737 2.81143C9.87487 2.64069 9.7329 2.49895 9.562 2.40041C9.39111 2.30187 9.19731 2.25 9.00004 2.25C8.80277 2.25 8.60897 2.30187 8.43808 2.40041C8.26718 2.49895 8.12521 2.64069 8.02638 2.81143V2.81143Z"
                stroke="#CF3030"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9 13.5C9.46599 13.5 9.84375 13.1222 9.84375 12.6562C9.84375 12.1903 9.46599 11.8125 9 11.8125C8.53401 11.8125 8.15625 12.1903 8.15625 12.6562C8.15625 13.1222 8.53401 13.5 9 13.5Z"
                fill="#CF3030"
              />
            </>
          ),
        }
      case 'show-password':
        return {
          width: 18,
          height: 18,
          icon: (
            <>
              <path
                d="M9 3.93695C3.375 3.93695 1.125 9 1.125 9C1.125 9 3.375 14.062 9 14.062C14.625 14.062 16.875 9 16.875 9C16.875 9 14.625 3.93695 9 3.93695Z"
                stroke="currentColor"
                strokeOpacity="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9 11.8126C10.5533 11.8126 11.8125 10.5534 11.8125 9.00006C11.8125 7.44676 10.5533 6.18756 9 6.18756C7.4467 6.18756 6.1875 7.44676 6.1875 9.00006C6.1875 10.5534 7.4467 11.8126 9 11.8126Z"
                stroke="currentColor"
                strokeOpacity="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </>
          ),
        }
      case 'hide-password':
        return {
          width: 18,
          height: 18,
          icon: (
            <>
              <path
                d="M14.1433 8.95111L15.7471 11.729"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10.8408 10.4951L11.341 13.3317"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.15285 10.4937L6.65259 13.3308"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M3.85373 8.94885L2.24219 11.7401"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2.25 7.37402C3.43213 8.83726 5.59927 10.6875 9.00004 10.6875C12.4008 10.6875 14.5679 8.83727 15.7501 7.37404"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </>
          ),
        }
      case 'sample-box-small':
        return {
          width: 40,
          height: 33,
          icon: (
            <>
              <rect width="40" height="33" fill="white" />
              <path
                d="M20.0617 10.8076L32.5096 9.34923C33.2387 9.24506 33.9679 9.66173 34.2283 10.3388L35.895 14.2451C36.4158 15.443 35.7387 16.7971 34.4367 17.0576L26.1554 18.9846C25.1658 19.2451 24.1762 18.8284 23.5512 17.9951L20.0617 13.0471L16.52 17.9951C15.895 18.8284 14.9054 19.2451 13.9158 18.9846L5.63457 17.0576C4.33249 16.7971 3.6554 15.443 4.17624 14.2451L5.8429 10.3388C6.10332 9.66173 6.83249 9.24506 7.56165 9.34923L20.0617 10.8076ZM5.68665 14.8701C5.58249 15.1305 5.73874 15.3909 5.99915 15.443L14.2804 17.3701C14.5929 17.4742 14.9575 17.318 15.1658 17.0576L18.5512 12.318L7.35332 11.0159L5.68665 14.8701ZM24.9054 17.0576C25.1137 17.318 25.4783 17.4742 25.7908 17.3701L34.0721 15.443C34.3325 15.3909 34.4887 15.1305 34.3846 14.8701L32.7179 11.0159L21.52 12.318L24.9054 17.0576ZM31.7283 19.0888L33.395 18.6201V26.4846C33.395 27.6305 32.6137 28.6201 31.4679 28.9326L20.6346 31.6409C20.27 31.693 19.8533 31.693 19.4367 31.6409L8.60332 28.9326C7.45749 28.6201 6.72832 27.6305 6.72832 26.4846V18.6201L8.39499 19.0888V26.4846C8.39499 26.8492 8.60332 27.2138 9.01999 27.2659L19.2283 29.818V17.5784C19.2283 17.1617 19.5929 16.7451 20.0617 16.7451C20.4783 16.7451 20.895 17.1617 20.895 17.5784V29.818L31.0512 27.2659C31.4679 27.2138 31.7283 26.8492 31.7283 26.4846V19.0888Z"
                fill="#141414"
              />
            </>
          ),
        }
      case 'sample-box-large':
        return {
          width: 57,
          height: 47,
          icon: (
            <>
              <g clipPath="url(#clip0_1_2)">
                <path d="M57 0H0V47H57V0Z" fill="white" />
                <path
                  d="M28.5879 15.3926L46.3262 13.3156C47.3652 13.1672 48.4043 13.7606 48.7753 14.725L51.1504 20.2885C51.8925 21.9946 50.9276 23.9231 49.0723 24.2942L37.2714 27.0387C35.8613 27.4097 34.4511 26.8162 33.5605 25.6294L28.5879 18.5822L23.541 25.6294C22.6504 26.8162 21.2402 27.4097 19.83 27.0387L8.02926 24.2942C6.1738 23.9231 5.20894 21.9946 5.95114 20.2885L8.32613 14.725C8.69723 13.7606 9.7363 13.1672 10.7754 13.3156L28.5879 15.3926ZM8.10348 21.1786C7.95505 21.5495 8.1777 21.9204 8.54879 21.9946L20.3496 24.7392C20.7949 24.8875 21.3144 24.665 21.6113 24.2942L26.4355 17.5438L10.4785 15.6893L8.10348 21.1786ZM35.4902 24.2942C35.787 24.665 36.3066 24.8875 36.7519 24.7392L48.5527 21.9946C48.9238 21.9204 49.1464 21.5495 48.9981 21.1786L46.623 15.6893L30.666 17.5438L35.4902 24.2942ZM45.2128 27.1871L47.5879 26.5195V37.7205C47.5879 39.3525 46.4745 40.762 44.8418 41.207L29.4043 45.0643C28.8848 45.1385 28.291 45.1385 27.6973 45.0643L12.2597 41.207C10.6269 40.762 9.58786 39.3525 9.58786 37.7205V26.5195L11.9629 27.1871V37.7205C11.9629 38.2398 12.2597 38.759 12.8535 38.8332L27.4003 42.4681V25.0359C27.4003 24.4424 27.9199 23.8491 28.5879 23.8491C29.1816 23.8491 29.7754 24.4424 29.7754 25.0359V42.4681L44.248 38.8332C44.8418 38.759 45.2128 38.2398 45.2128 37.7205V27.1871Z"
                  fill="#141414"
                />
              </g>
              <defs>
                <clipPath id="clip0_1_2">
                  <rect width="57" height="47" fill="white" />
                </clipPath>
              </defs>
            </>
          ),
        }
      case 'filter-new':
        return {
          width: 18,
          height: 18,
          icon: (
            <>
              <path d="M1 1H13" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
              <path d="M2 5H12" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
              <path d="M3 9H11" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
            </>
          ),
        }
      default:
        return { icon: null }
    }
  }

  const { icon, width, height } = selectIcon()
  if (!icon) return null
  return (
    <svg
      name={name}
      className={className}
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      width={setWidth || width}
      height={setHeight || height}
      style={{ color: 'inherit', ...style }}
    >
      {icon}
    </svg>
  )
}
